import common from "./common";

export default {
  ...common.button,
  color: `omegaLighter`,
  bg: `omegaCustom`,
  borderColor: `omega`,
  ":hover": {
    color: `white`,
    borderColor: `alpha`,
  },
};

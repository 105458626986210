export default {
  color: `omegaDarker`,
  textDecoration: `none`,
  userSelect: `none`,
  ":visited": {
    color: "omegaLighter",
  },
  ":hover": {
    color: "alpha",
  },
  p: 1,
  m: 0,
  mx: 2,
  svg: {
    display: `block`,
    mx: `auto`,
    mb: 1,
  },
}

export default {
  color: `#000`,
  textDecoration: `none`,
  userSelect: `none`,
  ":visited": {
    color: "#000",
  },
  ":hover": {
    color: "alpha",
  },
}

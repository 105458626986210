import group from "./group";
import primary from "./primary";
import mute from "./mute";
import disabled from "./disabled";
import dark from "./dark";
import custom from "./custom";
import darkOutline from "./dark-outline";
import white from "./white";
import simple from "./simple";
import icon from "./icon";
import iconDark from "./icon-dark";

export default {
  group,
  primary,
  mute,
  disabled,
  dark,
  custom,
  "dark-outline": darkOutline,
  white,
  simple,
  icon,
  "icon-dark": iconDark,
};

const button = {
  display: `inline-flex`,
  minWidth: `10rem`,
  alignItems: `center`,
  justifyContent: `space-evenly`,
  outline: 0,
  userSelect: `none`,
  '-webkit-tap-highlight-color': `transparent`,
  '-webkit-touch-callout': `none`,
  '-webkit-user-select': `none`,
  '-khtml-user-select': `none`,
  '-moz-user-select': `none`,
  '-ms-user-select': `none`,
  borderRadius: `full`,
  cursor: `pointer`,
  borderWidth: `md`,
  borderStyle: `solid`,
  transition: `all 250ms ease`,
}

export default {
  button,
}
